import React from "react";
import { slide as Menu } from "react-burger-menu";

export default (props) => {
  return (
    // Pass on our props
    <Menu right>
      <a className="menu-item" href="/">
        Home
      </a>
      <a className="menu-item" href="/key-services">
        Key Services
      </a>
      <a className="menu-item" href="/accommodation">
        Accommodation
      </a>
      <a className="menu-item" href="/contact">
        Contact
      </a>
      <a
        className="menu-item"
        href="tel:+3536255121"
        style={{ color: "#ffff00" }}
      >
        Phone: (062) 55121
      </a>
    </Menu>
  );
};
