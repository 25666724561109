import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link } from "gatsby";

const FooterOuter = styled("footer")`
  background-color: #363839;
  color: #ffffff;
  padding: 20px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    a { color: white; font-weight: normal;}
  }
`;

const FooterLink = styled(Link)`
  font-size: 0.8rem;
  font-weight: 200;
  display: block;
  color: #efefef;
`;

const Footer = () => (
  <FooterOuter>
    <div
      className="main-content"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div>
        <h4>Contact</h4>
        <p>
          Phone: 062-55121
          <br />
          Email: info@deerparknursinghome.ie
        </p>
      </div>

    </div>
  </FooterOuter>
);

export default Footer;
