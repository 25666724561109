import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link } from "gatsby";
import MobileMenu from "./mobile-menu";
import deerparkLogo from "../assets/images/dp-logo-200w.png";

const NavLink = styled(Link)`
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1;
  margin: 0 0.5rem 0 0;
  padding: 0.35rem;
  text-decoration: none;
  color: #fff;

  &.current-page {
    background-color: #33afc0;
    border-radius: 3px;
    color: white;
  }
`;

const Header = () => (
  <>
    <MobileMenu />
    <header
      css={css`
        padding: 80px;
        background-color: #3f4d68;
        background-size: normal;
        color: white;
        // border-bottom: solid 1px #ccc;
        display: flex;
        justify-content: space-between;
        padding: 0.5rem calc((100vw - 980px) / 2);
      `}
    >
      <NavLink className="sk-logo" to="/">
        <img style={{ maxWidth: "280px"}} src={deerparkLogo} />
      </NavLink>
      <div>
        <nav
          css={css`
            margin-top: 30px;
            padding: 0;
            text-transform: uppercase;
          `}
          className="main-nav"
        >
          <NavLink to="/" activeClassName="current-page">
            Home
          </NavLink>
          <NavLink to="/key-services" activeClassName="current-page">
            Key Services
          </NavLink>
          <NavLink to="/accommodation" activeClassName="current-page">
            Accommodation
          </NavLink>
          <NavLink to="/contact" activeClassName="current-page">
            Contact
          </NavLink>
        </nav>
      </div>

      {/* site name */}
      {/* site navigation */}
    </header>
  </>
);

export default Header;
