import React from "react";
import { Global, css } from "@emotion/core";
import Helmet from "react-helmet";
import Header from "./header";
import Footer from "./footer";
import useSiteMetadata from "../hooks/use-sitemetadata";

import "./layout.css";

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata();

  return (
    <>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            margin: 0;
          }

          // * + * {
          //   margin-top: 1rem;
          // }

          html,
          body {
            margin: 0;
            color: #555;
            font-family: 'Montserrat', Arial, Helvetica, sans-serif;
            font-weight: 700;
            line-height: 1.5;
            font-size: 16px;
          }

          #gatsby-focus-wrapper {
            min-height: 100%;
            display: grid;
            grid-template-rows: auto 1fr auto;
            grid-template-columns: 100%;          }

          header{
            height: 100px;
          }q

          /* remove margin for the main div that gatsby mounts into */
          > div {
            margin-top: 0;
          }

          h1,
          h2,
          h3 {
            color: ;
          }

          form.contact-form p {
            display: block;
            padding-bottom: 10px;
          }

          form.contact-form label {
            color: #333;
            font-weight: 500;
            font-size: 0.8rem;
            display:block;
          }

          .contact-form input[type=text],
          .contact-form input[type=email],
          .contact-form textarea {
            width: 90%;
            font-size: 0.9rem;
            border: solid 1px #ccc;
            padding: 4px;
            border-radius: 3px;
          }

          .contact-form textarea {
            height: 100px;
          }
        `}
      />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
